<template>
    <div class="social-media-item" v-if="socialObject != null">
        <img @click="imageClick" :src="imageUrl" :alt="socialObject.name" />
    </div>
</template>

<script>
export default {
    name: "social-media-item",
    props: [
        'socialObject'
    ],
    computed: {
        imageUrl: function() {
            switch(this.socialObject.type) {
                case "linkedin":
                    return require("../../assets/social/1384036-social-media/png/010-linkedin.png");
            }

            return '';
        }
    },
    methods: {
        imageClick: function() {
            window.open(this.socialObject.link, "_blank");
        }
    }
}
</script>

<style scoped>
    #socialMediaItem {
        height: 40px;
    }

    img {
        height: 100%;
        width: 100%;
        cursor: pointer;
    }
</style>