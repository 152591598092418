<template>
    <div id="socialPanel">
        <div class="social-media-item" id="open_preferences_center">
            <img  :src="require('../../assets/icons/cookie.png')" alt="Manage Cookies" />
        </div>
        <div class="social-media-wrapper" v-for="socialObject in socialObjects" :key="socialObject.id">
            <social-media-item v-if="socialObject != null" :social-object="socialObject"></social-media-item>
        </div>

    </div>
</template>

<script>
import SocialMediaItem from "./SocialMediaItem.vue"

export default {
    name: "social-media-panel",
    props: [
        "socialObjects"
    ],
    components: {
        SocialMediaItem
    }
}
</script>

<style scoped>
    #socialPanel {
        overflow: hidden;
    }

    #open_preferences_center img {
        width: 100%;
        margin: 15px 0px;
        cursor: pointer;
    }
</style>