import App from './App.vue';

import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import HomeComponent from './components/HomeComponent.vue';
import AboutWebsite from './components/AboutWebsite.vue';
import GuidGenerator from './components/GuidGenerator/GuidGenerator.vue'
import CookiePolicy from './components/CookiePolicy.vue';
import PrivacyPolicy from './components/PrivacyPolicy.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: HomeComponent, name: "Home" },
    { path: '/about-website', component:  AboutWebsite, name: "About"},
    { path: '/guid-generator', component: GuidGenerator, name: "GUID Generator"},
    { path: '/cookie-policy',  component: CookiePolicy, name: "Cookie Policy"},
    { path: '/privacy-policy', component: PrivacyPolicy, name: "Privacy Policy"}
  ]
})

const app = createApp(App);

app.use(router);

app.mount('#app');

// const appObj = new Vue({
//   render: h => h(App),
// }).$mount('#app');

// appObj