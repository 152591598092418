<template>
    <div id="home" class="content">
        <h1>Josh Potterton</h1>
        <div class="img-wrapper">
            <img src="../assets/me.jpg" />
        </div>
        <div class="desc">
            <h3>Frontend Web Developer</h3>
            <p><span>Languages</span> | JavaScript, TypeScript, HTML, CSS</p>
            <p><span>Libraries/Frameworks</span> | Sencha Touch 2.4, Angular 2+, Vue.js, jQuery</p>
            <p><span>Other Technologies</span> | Google Analytics, Google Tag Manager, Google DFP, Omniture, ComScore, OneTrust</p> 
            <p><span>Currently Learning</span> | AWS Cloud Practitioner</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeComponent'
}
</script>

<style scoped>
    #home {
        width: 100%;
        position:relative;
        overflow:hidden;
        text-align: center;
    }

    .img-wrapper {
        height: 200px;
        width: 200px;
        border-radius: 100%;
        position:relative;
        overflow: hidden;
        display:inline-block;
        margin: 10px;
    }

    .img-wrapper img {
        height: 100%;
        width: 100%;
    }

    span {
        font-weight: 700;
    }
</style>