<template>
  <div id="app">
    <!-- Menu -->
    <!-- <div class="menu-icon" @click="toggleMenu">
      <img src="./assets/menu.png" />
    </div> -->

    <div id="menu-icon" :class="{ 'open': menuOpen }" @click="toggleMenu">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <menu-component class="menu" :menu-open="menuOpen"></menu-component>

    <!-- Content -->
    <main>
          <router-view @broadcast-update="showToast"></router-view>
    </main>

    <!-- Floating/Absolute -->
    <social-media-panel 
      class="social-media-panel"
      :social-objects="socialObjects"
    ></social-media-panel>

    <!-- Toast like message to say text copied-->
    <transition name="toast">
      <div class="toast"  v-if="toastMsg != null && toastMsg.trim() != ''">{{toastMsg}}</div>
    </transition>
  </div>
</template>

<script>
import SocialMediaPanel from './components/SocialMediaPanel/SocialMediaPanel.vue';
import MenuComponent from './components/MenuComponent/MenuComponent.vue';

export default {
  name: 'App',
  components: {
    SocialMediaPanel,
    MenuComponent
  },
  watch:{
    $route (to){
        this.menuOpen = false;

        if(!!to && !!to.name && to.name.trim() != '') {
          document.title = to.name;
        }
    },
  }, 
  created: function () {
    var _this = this;

    this.cookieConsentChangeListener = document.addEventListener("cc_preferencesCenterSavePressed", function() {
        window['ga-disable-G-SJNJFWJM4F'] = !_this.checkCookieConsent("tracking"); 

        if(window['ga-disable-G-SJNJFWJM4F'] && typeof gtag != 'undefined') {
          _this.deleteCookie("_ga", '/', location.hostname);
          _this.deleteCookie("_ga_SJNJFWJM4F", '/', location.hostname);
        }
    })
  },
  data: function() {
    return {
      socialObjects: [
        {
          id: "linkedin-0",
          type: "linkedin",
          name: "LinkedIn",
          link: "https://www.linkedin.com/in/joshua-potterton-a7387a108/"
        }
      ],
      menuOpen: false,
      isFirstRoute: true,
      toastMsg: ''
    }
  },
  methods: {
    toggleMenu: function() {
      this.menuOpen = !this.menuOpen;
    },

    deleteCookie: function( name, path, domain ) {
      if( this.getCookie( name ) ) {
        document.cookie = name + "=" +
        ((path) ? ";path="+path:"")+
          ((domain)?";domain="+domain:"") +
          ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
      }
    },

    showToast: function(msg) {
      var me = this;
                //var toastDivs = document.getElementsByClassName("toast");

                //if(toastDivs != null && toastDivs[0] != null) {
                    //var toastDiv = toastDivs[0];
                    //toastDiv.innerHTML = text;
                    this.toastMsg = msg;

                    setTimeout(function() {
                        //toastDiv.style.opacity = "0";
                        setTimeout(function() {
                            //toastDiv.style.opacity = "";
                            //toastDiv.innerHTML = "";
                            me.toastMsg = '';
                        }, 600);

                    }, 1000);
                //}
    },
    getCookie: function(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
    checkCookieConsent: function(type) {
      try {
        var cookieConsentJson = JSON.parse(decodeURIComponent(this.getCookie("cookie_consent_level")));
        return cookieConsentJson[type];
      }
      catch(ex) {
        return false;
      }
    }
  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: 'Source Code Pro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
  height: 100%;
  width: 100%;
  background-color: #05cc7a;
}

html, body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
}

main {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  padding: 10px 60px;
}

* {
  box-sizing: border-box;
}

.social-media-panel {
  position:absolute;
  width: 40px;
  bottom: 0px;
  left: 0px;
  margin: 10px;
  z-index: 100;
}

.menu-icon{
  position: absolute;
  width: 40px;
  top: 0px;
  left: 0px;
  margin: 10px;
  cursor: pointer;
}

.menu-icon img {
  height: 100%;
  width: 100%;
  width: 32px;
  height: 32px;
}

.menu {
  z-index:99;
  position: absolute;
  top:0px;
  left: 0px;
}

#menu-icon {
  top: 0px;
  left: 0px;
  width: 32px;
  height: 32px;
  position: absolute;
  margin: 10px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 100;
}

#menu-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #2c3e50;;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#menu-icon span:nth-child(1) {
  top: 0px;
}

#menu-icon span:nth-child(2), #menu-icon span:nth-child(3) {
  top: 14px;
}

#menu-icon span:nth-child(4) {
  top: 29px;
}

#menu-icon.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#menu-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#menu-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#menu-icon.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.btn {
  background-color:#2c3e50;
                  min-width: 100px;
                color: white;
                border: none;
                padding: 15px;
                font-size: 0.8em;
                cursor: pointer;
                margin: 10px 0px;
                outline:none;
                -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.6);
                -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.6);
                box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.6);
}

.toast {
    background-color: rgba(0,0,0,0.7);
    color: white;
    border: none;
    padding: 15px;
    font-size: 0.8em;
    cursor: pointer;
    margin: 10px 0px;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.4);
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    transition: all ease 0.5s;
    /* opacity: 1; */
}

.toast-enter-from, .toast-leave-to {
  opacity: 0;
}

.toast-enter-to, .toast-leave-from {
  opacity: 1;
}

.toast-enter-active, .toast-leave-active {
  transition: all 0.5s ease;
}

</style>
