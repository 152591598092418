<template>
    <div class="content">
        <h1>Credits</h1>
        <p>Favicon generated at <a href="https://favicon.io/favicon-generator/" title="favicon.io">https://favicon.io/favicon-generator/</a></p>
        <p>Social media icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></p>
        <p>Source Code Pro font, which is used on this website, can be found at <a href="https://fonts.google.com/specimen/Source+Code+Pro?query=code&preview.text_type=custom" title="Google Fonts">Google Fonts</a></p>
        <p>Cookie policay generated at <a href="https://www.cookiepolicygenerator.com" title="cookiepolicygenerator.com">https://www.cookiepolicygenerator.com</a></p>
    </div>
</template>

<script>
export default {
    
}
</script>

<style>
    .credit-wrapper {
    
    }
</style>