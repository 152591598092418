<template>
    <router-link  class="menu-item" v-if="menuItem != null && menuItem.type == 'single'" :to='menuItem.path'>
        {{menuItem.name}}
    </router-link>    

    <div class="menu-item menu-expand" @click="toggleAccordian" v-else-if="menuItem != null 
        && menuItem.type == 'expand' 
        && menuItem.childItems != null && menuItem.childItems.length > 0">
        <p>
            <span class="expand-label plus" v-show="!isExpanded">+</span>
            <span class="expand-label minus" v-show="isExpanded">-</span>
            {{menuItem.name}}
        </p>

        <transition name="menuitem"
            @beforeEnter="beforeEnter"
            @enter="enter"
            @leave="leave">
            <div class="child-menu-items" v-if="isExpanded">
                <router-link  class="menu-item" v-for="childItem of menuItem.childItems" :key='childItem.name' :to='childItem.path'>
                    {{childItem.name}}
                </router-link> 
            </div>
        </transition>

    </div>
</template>

<script>
export default {
    name: 'menu-item',
    props: [
        'menuItem'
    ],
    data: function() {
        return {
            isExpanded: false,
            totalHeight: 0
        }
    },
    methods: {
        toggleAccordian: function() {
            this.isExpanded = !this.isExpanded;
        },

        beforeEnter: function(el) {
            el.style.height = '0px';
            this.totalHeight = 0;
        },

        enter: function(el, done) {
            var children = el.children;
            for(var i = 0; i < children.length; i++) {
                var child = children[i];
                this.totalHeight += child.offsetHeight + 20;
            }

            console.log(this.totalHeight);

            const transitionEndFunc = function() {
                done();
                el.removeEventListener('transitionend', transitionEndFunc)
            }
            el.addEventListener('transitionend', transitionEndFunc);

            el.style.height = this.totalHeight + 'px';

        },

        leave: function(el, done) {
            const transitionEndFunc = function() {
                done();
                el.removeEventListener('transitionend', transitionEndFunc)
            }
            el.addEventListener('transitionend', transitionEndFunc);

            el.style.height = '0px';
        }
    }
}
</script>

<style scoped>
    .menu-item {
        margin: 10px;
        cursor: pointer;
        text-decoration: none;
        color: inherit;
        display: block;
    }

    .child-menu-items {
        overflow: hidden;
        transition: all 0.5s ease;
    }

    /* .menuitem-enter-from, .menuitem-leave-to {
        max-height: 0px;
    }

    .menuitem-enter-active, .menuitem-leave-active {
        transition: all 0.5s ease;
    }

    .menuitem-enter-to, .menuitem-leave-from {
        max-height: 1000px;
    }  */


</style>