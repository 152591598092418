<template>
    <div id="menu-container" :style="{
        width: menuOpen ? '100%' : '0px',
        padding: menuOpen ? '10px 60px' : '0px',
    }">
        <h1>Menu</h1>

        <div class="menu-content">
            <menu-item v-for="menuItem in menuItems" :key="menuItem.id" :menuItem="menuItem"></menu-item>
        </div>

        <!-- <div class="close-wrapper">
            <img src="../../assets/close.png" alt="Close" />
        </div> -->

    </div>
</template>

<script>
import MenuItem from './MenuItem.vue';

export default {
    name: 'menu-component',
    components: {
        MenuItem
    },
    props: [
        'menuOpen'
    ],
    data: function() {
        return {
            menuItems: [
                {
                    id: 'home-0',
                    type: 'single',
                    name: 'Home',
                    path: '/'
                },
                {
                    id: 'tools-0',
                    type: 'expand',
                    name: 'Online Tools',
                    childItems: [
                        {
                            id: 'guid-gen-0',
                            type: 'single',
                            name: 'GUID Generator',
                            path: '/guid-generator'
                        }
                    ]
                },
                {
                    id: 'about-0',
                    type: 'single',
                    name: 'About Website',
                    path: '/about-website'
                },
                {
                    id: 'cookie-policy',
                    type: 'single',
                    name: 'Cookie Policy',
                    path: '/cookie-policy'
                },
                {
                    id: 'privacy-policy',
                    type: 'single',
                    name:'Privacy Policy',
                    path: '/privacy-policy'
                }
            ]
        }
    }
}
</script>

<style scoped>
    #menu-container {
        height: 100%;
        width: 100%;
        background-color: #038650;
        text-align: center;
        overflow-x:hidden;
        transition: 0.8s ease all;
        display: flex;
        flex-direction: column;
    }

    .menu-content {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .close-wrapper {
        position:absolute;
        top: 0px;
        left: 0px;
        height: 40px;
        width: 40px;
        margin: 10px;
    }

    .close-wrapper img {
        height: 32px;
        width: 32px;
    }
    
</style>