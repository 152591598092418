<template>
    <div class="guid-generator-container">
        <h1>Potter's GUID Generator</h1>

        <!-- GUID Generator-->
        <div class="guid-box">
            <form @submit.prevent="createNewGuid()">
                <div class="guid-desc">Need a GUID? Use this tool to generate yourself a GUID. Once created, click the guid to copy to your clipboard</div>
                <input class="guid-btn-submit btn" type="submit" value="Show me a GUID!" />
                <input class="save-btn btn" :class="{
                    'disabled': currentGuid == null
                }" type="button" value="Save GUID" @click="showSaveGuidForm()"/>
            </form>

            <div class="generated-guid copy" v-if="currentGuid != null" @click="copyToClipboard($event.target)">{{currentGuid}}</div>

            <!--Stored GUIDs-->
            <div class="generated-guid-box" v-if="showGuidTable">
                <div class="stored-guid-box">
                    <div v-for="(guid, name) in localStorageData.guids" 
                        :key="guid + '_' + name" 
                        :class="['guid-wrapper', 'stored-guid-item', 'row-' + name]">
                            <div class='stored-guid-name cell'>{{name}}</div>
                            <div class='stored-guid-val cell copy' @click='copyToClipboard($event.target)'>{{guid}}</div>
                            <div class='del-stored-guid-btn cell' @click='deleteGuid(name)'>Delete</div>
                    </div>
                </div>
            </div>

        </div>

        <!-- Save Guid Prompt -->
        <div class="modal" v-if="showSaveModalPrompt">
            <div class="modal-container">
                <div class="modal-title">Save GUID</div>
                <div class="guid">Enter a name for the GUID.</div>
                <form class="save-form" @submit.prevent="saveGuid($event)">
                    <input class="guid-name-input" name="guidName" placeholder="GUID Name" type="text" />
                    <div class="error-txt" v-if="errorText != null && errorText.trim() != ''">{{errorText}}</div>
                    <input class="guid-btn-submit btn" type="submit" value="Save" />
                </form>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'guid-generator',
    data: function() {
        return {
            guidsCreated: [],
            currentGuid: null,
            showSaveModalPrompt: false,
            localStorageData: {
                guids: {}
            },
            localStorageDataKey: 'guidGeneratorData',
            errorText: null
        }
    },
    emits:[
        'broadcast-update'
    ],
    computed: {
        showGuidTable: function() {
            return this.localStorageData != null && this.localStorageData.guids != null && Object.keys(this.localStorageData.guids).length > 0
        }
    },
    methods: {
        createNewGuid: function() {
            var newGuid = this.generateGuid();

            this.guidsCreated.push(newGuid);
            this.displayGuid(newGuid);
        },
        generateGuid: function() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        displayGuid: function (guid) {
            this.currentGuid = guid;
        },

        copyToClipboard: function(div) {

            var tempTextArea = document.createElement("textarea");

            tempTextArea.value = div.innerHTML;
            tempTextArea.setAttribute('readonly', '');
            tempTextArea.style.position = 'absolute';
            tempTextArea.style.left = '-9999px';
            document.body.appendChild(tempTextArea);
            tempTextArea.select();
            document.execCommand('copy');
            document.body.removeChild(tempTextArea);
            //delete tempTextArea;

            this.showToast("GUID Copied!");
        },

        showSaveGuidForm: function() {
            if(this.currentGuid != null && this.currentGuid.trim() != '') {
                this.showSaveModalPrompt = true;
            }
        },

        saveGuid: function (event) {
            console.log(event.target.guidName.value);
            var name = event.target.guidName.value;

            if(name != null && name.trim().length > 0) {
                if(this.localStorageData.guids[name] == null) {
                    this.localStorageData.guids[name] = this.currentGuid;
                    localStorage.setItem(this.localStorageDataKey, JSON.stringify(this.localStorageData));
                    this.displayErrorTxt(null);
                    //hideSaveGuidForm();
                    //this.addGuidToTable(name, this.localStorageData.guids[name]);
                    this.showSaveModalPrompt = false;
                }
                else {
                    this.displayErrorTxt('Name already exists');
                }
            }
            else {
                this.displayErrorTxt('Not enough characters');
            }
        },
        
        showToast: function(msg) {
            this.$emit('broadcast-update', msg);
        },

        displayErrorTxt: function(msg) {
            this.errorText = msg;
        },

        deleteGuid: function(guid) {
            if(this.localStorageData.guids[guid]) {
                delete this.localStorageData.guids[guid];
                localStorage.setItem(this.localStorageDataKey, JSON.stringify(this.localStorageData));

                this.showToast('GUID Deleted!');
            }
        }
    },
    mounted: function() {
        try {
            var copy = localStorage.getItem(this.localStorageDataKey);
            if(copy) {
                this.localStorageData = JSON.parse(copy);
            }

        }
        catch(ex) {
            console.error("Error");
        }
    }
}
</script>

<style scoped>
           .guid-generator-container {
               text-align: center;
           }

           .generated-guid {
               margin: 10px 0px;
           }

            .container {
                /* height: 100%; */
                width: 100%;
                position: relative;
                padding: 0px 20px;
            }

            .guid-box {
                text-align: center;
                padding: 20px;
            }

            .guid-desc {
                margin: 10px 0px;
                font-size: 0.8em;
            }

            .btn {
                margin: 5px;
            }

            .modal {
                display: inline-block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0px;
                left: 0px;
                background-color: rgba(0,0,0,0.3);
            }

            .modal-container {
                max-height: 100%;
                width: 100%;
                background-color: white;
                max-height: 350px;
                max-width: 350px;
                padding: 15px;
                -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.4);
                    -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.4);
                    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.4);
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            .modal-container .modal-title {
                font-size: 1.5em;
                text-align: center;
            }

            .guid {
                font-size: 0.8em;
            }

            .guid-name-input {
                -webkit-appearance: none;
                width: 100%;
                height: 40px;
                border: 2px solid rgb(123, 120, 120);
                padding: 20px 10px;
            }

            .save-form {
                margin-top: 20px;
                margin-bottom: 0px;
                text-align: center;
            }

            .error-txt {
                color: red;
            }

            .error-txt:empty {
                display: none;
            }

            .save-btn.disabled {
                cursor: not-allowed;
                background-color: gray;
                color: black;
            }

            .generated-guid-box {
                margin: 10px 0px;
                width: 100%;
                display: flex;
                border: 1px solid #9c9c9c;
                -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
                    -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
                    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
            }

            .generated-guid-box:empty {
                display: none;
            }

            .stored-guid-box {
                flex: 1;
                display: table;
                table-layout: fixed;
            }       
            
            .stored-guid-box:empty {
                display: none;
            }

            .stored-guid-item {
                display: table-row;
                width: 100%;
                table-layout: fixed;
            }

            .cell {
                display: table-cell;
                padding: 5px 10px;
            }

            @media screen and (max-width: 500px) {
                .stored-guid-item {
                    display: inline-block;
                    border-bottom: 1px solid #9c9c9c;
                }

                .cell {
                    display: block;
                }
            }

            .del-stored-guid-btn {
                cursor: pointer;
                text-align: center;
            }

            .copy {
                cursor: copy;
            }
</style>